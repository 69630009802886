/* global IntersectionObserver */
if( document.readyState !== 'loading' ) {
  lazyLoad();
} else {
  document.addEventListener("DOMContentLoaded", function(event) {
    lazyLoad()
  });
}

function lazyLoad() {
  const lazyImages = [].slice.call(document.querySelectorAll('img.lazy'));
  if ('IntersectionObserver' in window) {
    const lazyImageObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          const lazyImage = entry.target;
          lazyImage.src = lazyImage.dataset.src;
          lazyImage.classList.remove('lazy');
          lazyImageObserver.unobserve(lazyImage);
        }
      });
    });

    lazyImages.forEach(function (lazyImage) {
      lazyImageObserver.observe(lazyImage);
    });
  } else {
    console.log('Get a modern browser: https://browsehappy.com/');
    document.querySelectorAll('.lazy').forEach((element) => {
      element.classList.remove('lazy');
      element.src = element.dataset.src;
    });
  }
}
