// Header
let navigation = document.getElementById("navigation");

function toggleNavigation() {
  if (navigation.classList.contains("navigation--visible")) {
    navigation.scrollTop = 0;
    navigation.classList.remove("navigation--visible");
  }
  else {
    navigation.classList.add("navigation--visible");
  }
}

const header = {
	element: document.querySelector('header .header'),
	hamburger: document.querySelector('.toggle__hamburger'),
	nav: document.querySelector('.header__container'),
	body: document.querySelector('body'),
	base: 0,
	init: () => {
		header.hamburger.addEventListener('click', header.toggleMenu);
		window.addEventListener('scroll', header.onScroll);
	},
	onScroll: (e) => {
		let direction = (window.scrollY > header.base) ? 'down' : 'up';
		header.base = window.scrollY;
		if (header.hamburger.classList === "hamburger hamburger--active") {
			//do nothing

		} else {
			// Sticky the header after 600px of scroll.

			if (window.scrollY < 300) {
				header.element.classList.remove('header--sticky');
			} else {
				if (direction === "up") {
					header.element.classList.add('header--sticky');
				} else {
					header.element.classList.remove('header--sticky');
				}
			}
		}

	},

	toggleMenu: () => {
		header.hamburger.classList.toggle('hamburger--active');
		header.nav.classList.toggle('header__container--active');
		header.body.classList.toggle('shadow');
	}

};

header.init();


// Footer
function openSubmenu(button) {
  var submenu = button.nextElementSibling;

  if (submenu.classList.contains("navigation__submenu--shown")) {
    submenu.classList.remove("navigation__submenu--shown");
    submenu.classList.add("navigation__submenu--hidden");
    button.style.transform = "none";
  } else {
    submenu.classList.remove("navigation__submenu--hidden");
    submenu.classList.add("navigation__submenu--shown");
    button.style.transform = "rotate(180deg)";
  }
}
